<template>
  <page-layout class="ml-24 mr-24 mt-16">
    <!-- 列表 -->
    <div class="bg-white">
      <p-table
        rowKey="id"
        :dataSource="dataSource"
        class="bg-white"
        dataKey="modal"
      >
        <p-t-column type="seq" width="120" title="序号" />
        <p-t-column
          field="file_name"
          min-width="260"
          title="文件名称"
          v-slot="{ row }"
        >
          <span
            style="color:#1684FB; cursor:pointer;"
            @click="handleDownload(row)"
            >{{ row.file_name }}</span
          >
        </p-t-column>
        <p-t-column
          field="create_time"
          min-width="240"
          title="生成时间"
          v-slot="{ row }"
        >
          <span>{{
            formatToDateTime(row.create_time, "yyyy-MM-dd hh:mm:ss")
          }}</span>
        </p-t-column>
        <p-t-column width="80" title="操作" fixed="right">
          <template v-slot="{ row }">
            <a-button type="link" style="padding:0;" @click="handleDelete(row)"
              >删除</a-button
            >
          </template>
        </p-t-column>
      </p-table>
    </div>
  </page-layout>
</template>
<script>
import { defineComponent, reactive } from "vue";
import { message } from "ant-design-vue";
import { useStore } from "vuex";
import { getInitTable } from "@/hooks/form-hook";
import { Table } from "@wlhy-web-components/common";
import {
  useFileDownloadPageApi,
  useFileDownloadUpdateFlagApi,
  useFileDownloadDeleteApi
} from "@/apis/download";
import { formatToDateTime } from "@wlhy-web-lib/shared";

export default defineComponent({
  name: "account-enterprise",
  setup() {
    const store = useStore();
    const tableDataSource = reactive(getInitTable());

    const fileDownloadPageApi = useFileDownloadPageApi();
    const fileDownloadDeleteApi = useFileDownloadDeleteApi();
    const fileDownloadUpdateFlagApi = useFileDownloadUpdateFlagApi();

    const getConsultingList = Table.useTableConfig({
      dataSource: tableDataSource,
      async fetchData() {
        const data = {
          status: "COMPLETED",
          size: tableDataSource.meta.pageSize,
          page: tableDataSource.meta.currentPageNo
        };
        const res = await fileDownloadPageApi(data);
        return { records: res.list, total: res.total };
      }
    });

    (async () => {
      const res = await fileDownloadUpdateFlagApi();
      if (res) {
        store.commit("app/UPDATE_DOWNLOAD_COUNT", false);
      }
    })();

    // 删除操作
    const handleDelete = async row => {
      const res = await fileDownloadDeleteApi({ id: row.id });
      if (res) {
        message.success("删除成功");
        getConsultingList();
      }
    };

    // 下载
    const handleDownload = async row => {
      window.open(row.file_path);
    };

    return {
      getConsultingList,
      dataSource: tableDataSource,
      formatToDateTime,
      handleDelete,
      handleDownload
    };
  }
});
</script>
